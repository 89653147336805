import $ from "jquery";
import Api from "../utils/api";

export default class News {
  constructor() {
    this.grid = $("[data-news-grid]");
    this.load = $("[data-news-load]");

    this.displayedItems = NEWS_LIMIT_DEFAULT;

    this.init();
  }

  fetchNews = () => {
    this.load.addClass("is-loading");
    return Api.post("/news.json", {
      formData: {
        offset: this.displayedItems,
        limit: NEWS_LIMIT_DEFAULT,
        profileId: CURRENT_PROFILE_TYPEID,
      },
    })
      .then(({ results_count, html }) => {
        this.load.removeClass("is-loading");
        results_count < NEWS_LIMIT_DEFAULT
          ? this.load.hide()
          : this.load.show();

        this.grid.append(html);
        this.displayedItems += results_count;
      })
      .catch(error => {
        this.load.removeClass("is-loading");
        console.warn(error);
      });
  };

  init() {
    this.load.on("click", this.fetchNews);
  }
}
