class Api {
  encodeData = data => {
    return Object.keys(data)
      .map(function(key) {
        return [key, data[key]].map(encodeURIComponent).join("=");
      })
      .join("&");
  };

  createFormData = data => {
    const formData = new FormData();
    Object.keys(data).forEach(key => formData.append(key, data[key]));
    return formData;
  };

  parseResponse = response => {
    return response.json().then(json => ({
      status: response.status,
      ok: response.ok,
      json,
    }));
  };

  fetch = (url, options = {}) => {
    const defaultOptions = {
      credentials: "same-origin",
    };

    return new Promise((resolve, reject) => {
      fetch(url, { ...defaultOptions, ...options })
        .then(this.parseResponse)
        .then(response => {
          return response.ok
            ? resolve(response.json)
            : reject({ status: response.status, ...response.json });
        })
        .catch(error =>
          reject({
            networkError: error.message,
          })
        );
    });
  };

  get = (url, data = {}) => {
    return this.fetch(url, { method: "GET", ...data.options });
  };

  post = (url, data = {}) => {
    let body = {};

    switch (true) {
      case data.hasOwnProperty("json"):
        body = JSON.stringify(data.json);
        break;
      case data.hasOwnProperty("formData"):
        body = this.createFormData(data.formData);
        break;
      case data.hasOwnProperty("form"):
        body = new FormData(data.form);
        break;

      default:
        console.warn(
          "No data provided in Api.post(url, data). Request sent anyway"
        );
        break;
    }

    return this.fetch(url, {
      method: "POST",
      body,
      ...data.options,
    });
  };
}

export default new Api();
