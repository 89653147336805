import $ from "jquery";
import Api from "../utils/api";

export default class Contact {
  constructor() {
    this.form = $("[data-contact]").find("form");

    this.init();
  }

  handleResponse = (inputClass = "success", message) => {
    this.form.removeClass("is-loading");
    this.form
      .parent()
      .prepend(
        `<div class="contact__message contact__message--${inputClass}">${message}</div>`
      );

    $("html, body").animate(
      {
        scrollTop: 0,
      },
      300
    );

    grecaptcha
      .execute(window.grecaptchaKey, {
        action: "contact",
      })
      .then(function(token) {
        document.querySelector("[name='contact[recaptcha]']").value = token;
      });

    if (inputClass === "success") {
      this.form[0].reset();
    }
  };

  init = () => {
    this.form.on("submit", event => {
      event.preventDefault();

      this.form.addClass("is-loading");
      this.form
        .parent()
        .find(".contact__message")
        .remove();

      Api.post(this.form.attr("action"), {
        form: this.form[0],
      })
        .then(({ message }) => this.handleResponse("success", message))
        .catch(({ message }) => this.handleResponse("error", message));
    });
  };
}
