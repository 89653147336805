import $ from "jquery";;

export default class Accordion {
  constructor() {
    this.$accordion = $('[data-accordion]');
    this.$allItems = this.$accordion.find('[data-accordion-list]');
    this.init();
  }

  show = ($panelItem) => {
    $panelItem.addClass('is-open');
    $panelItem.find('[data-accordion-list]').slideDown()
  }

  hide = ($panelItem) => {
    $panelItem.removeClass('is-open');
    $panelItem.find('[data-accordion-list]').slideUp();
  }

  toggle = ($panelItem) => {
    console.log($panelItem);
    if ($panelItem.hasClass('is-open')) {
      this.hide($panelItem);
      return;
    }

    this.show($panelItem);
  }

  init = () => {
    var _this = this;
    this.$allItems.hide();
    this.show(this.$allItems.eq(0).parent());

    this.$accordion.find('[data-accordion-trigger]').click(function(ev) {
      ev.preventDefault();
      console.log('data', $(this));
      console.log('other', $(this).closest('[data-accordion-item]'));
      _this.toggle($(this).closest('[data-accordion-item]'));
    });
  };
}
