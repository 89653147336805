const config = {
  autocomplete: {
    componentRestrictions: {
      country: [
        "fr",
        "gp", // Gwada
        "mq", // Martinique
        "yt", // Mayotte
        "re", // Réunion
        // Seulement 5 pris en compte
        "gf", // Guyane
        "pm", // Saint Pierre
      ],
    },
  },
};

export default config;
