/* global CURRENT_PROFILE_TYPEID, DOC_LIMIT_DEFAULT, DOC_FILTER_DEFAULT, DOC_ORGANIZER_ANTECHRONO, COUNT_STRING */
import $ from "jquery";
import Api from "../utils/api";
import Modal from "../components/modal";

export default class Documents {
  constructor() {
    this.grid = $("[data-documents-grid]");
    this.load = $("[data-documents-load]");
    this.count = $("[data-documents-count]");
    this.filterRadios = $('input[name="filter"]');
    this.organizerSelect = $('select[name="organizer"]');

    this.modalInstance = new Modal();

    this.documentsTotal = parseInt(DOC_LIMIT_DEFAULT);
    this.filter = DOC_FILTER_DEFAULT;
    this.organizer = DOC_ORGANIZER_ANTECHRONO;

    this.init();
  }

  fetchNews = (offset = this.documentsTotal, limit = DOC_LIMIT_DEFAULT) => {
    this.load.addClass("is-loading");

    return Api.post("/documents.json", {
      formData: {
        profileId: CURRENT_PROFILE_TYPEID,
        offset,
        limit,
        filter: this.filter,
        organizer: this.organizer,
      },
    })
      .then(response => {
        this.load.removeClass("is-loading");
        response.results_count < limit
          ? this.grid.parent().addClass("is-end")
          : this.grid.parent().removeClass("is-end");

        return response;
      })
      .catch(error => {
        this.load.removeClass("is-loading");
        console.warn(error);
      });
  };

  updateCount = () => {
    this.count.html(COUNT_STRING.replace("%count%", this.documentsTotal));
  };

  init() {
    this.load.on("click", () => {
      this.fetchNews().then(({ html, results_count }) => {
        this.grid.append(html);
        this.modalInstance.init();
        this.documentsTotal += results_count;
        this.updateCount();
      });
    });

    this.filterRadios.on("change", () => {
      this.filter = $('input[name="filter"]:checked').val();

      const limit =
        this.documentsTotal < parseInt(DOC_LIMIT_DEFAULT)
          ? parseInt(DOC_LIMIT_DEFAULT)
          : this.documentsTotal;

      this.fetchNews(0, limit).then(({ html, results_count }) => {
        this.grid.html(html);
        this.modalInstance.init();
        this.documentsTotal = results_count;
        this.updateCount();
      });
    });

    this.organizerSelect.on("change", event => {
      this.organizer = $(event.target)
        .find(":selected")
        .val();

      this.fetchNews(0, this.documentsTotal).then(response => {
        this.grid.html(response.html);
        this.modalInstance.init();
      });
    });
  }
}
