import $ from "jquery";
import config from "../config";

/**
 * Location form
 */

class LocationForm {
  constructor() {
    this.form = $("[data-location-form]");
    this.input = $("[data-location-form]").find('input[type="search"');

    this.autocomplete = null;
    this.place = {};

    this.init();
  }

  init = () => {
    if (!this.input.length) return;
    this.autocomplete = new google.maps.places.Autocomplete(
      this.input[0],
      config.autocomplete
    );

    this.autocomplete.addListener("place_changed", () => {
      this.place = this.autocomplete.getPlace();
    });

    this.form.on("submit", event => {
      event.preventDefault();
      if (!this.place.geometry || !this.input.val().length)
        return this.input.focus();
      const { lat, lng } = this.place.geometry.location;

      window.location = `${FIND_URL}?location=${lat()},${lng()}&place=${
        this.place.name
      }`;
    });
  };
}

export default LocationForm;
