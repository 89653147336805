/* global CURRENT_PROFILE_TYPEID, SITE_SEARCH_TYPE_APPS, SITE_SEARCH_TYPE_ARTICLES, SITE_SEARCH_TYPE_DOCUMENTS, SITE_SEARCH_TYPE_NEWS, SITE_SEARCH_LIMIT_DEFAULT */
import $ from "jquery";
import Api from "../utils/api";
import Modal from "./modal";

export default class Search {
  constructor() {
    this.globalWrapper = $("[data-search]");
    this.resultsWrapper = $("[data-search-results]");
    this.form = $("[data-search-form]");

    this.modalInstance = new Modal();

    this.counts = {
      [SITE_SEARCH_TYPE_APPS]: 0,
      [SITE_SEARCH_TYPE_ARTICLES]: 0,
      [SITE_SEARCH_TYPE_DOCUMENTS]: 0,
      [SITE_SEARCH_TYPE_NEWS]: 0,
    };

    this.term = "";

    this.init();
  }

  searchTerm = () => {
    this.globalWrapper.addClass("is-loading");

    return Api.post("/site-search.json", {
      formData: {
        term: this.term,
        profileId: CURRENT_PROFILE_TYPEID,
      },
    })
      .then(response => {
        this.globalWrapper.removeClass("is-loading");
        return response;
      })
      .catch(error => console.warn(error));
  };

  loadMoreWithType = typeId => {
    this.globalWrapper.addClass("is-loading");

    return Api.post("/site-single-search.json", {
      formData: {
        term: this.term,
        searchType: typeId,
        offset: this.counts[typeId],
        limit: SITE_SEARCH_LIMIT_DEFAULT,
        profileId: CURRENT_PROFILE_TYPEID,
      },
    })
      .then(response => {
        this.globalWrapper.removeClass("is-loading");
        return response;
      })
      .catch(error => console.warn(error));
  };

  init() {
    const _this = this;

    // Load al template from search-results.html.twig with results from API
    this.form.on("submit", event => {
      event.preventDefault();
      this.term = this.form.find('input[type="search"]').val();

      this.searchTerm().then(
        ({ app_count, article_count, document_count, news_count, html }) => {
          // Update counts to track offset for each category
          this.counts[SITE_SEARCH_TYPE_APPS] = app_count;
          this.counts[SITE_SEARCH_TYPE_ARTICLES] = article_count;
          this.counts[SITE_SEARCH_TYPE_DOCUMENTS] = document_count;
          this.counts[SITE_SEARCH_TYPE_NEWS] = news_count;

          // Display results and refresh modal listeners
          this.resultsWrapper.html(html);
          this.modalInstance.init();
        }
      );
    });

    // Show corresponding panel on tab click
    $("body").on("click", "[data-search-tab]", function() {
      const index = $(this).index();
      $("[data-search-panel]").removeClass("is-active");
      $("[data-search-tab]").removeClass("is-active");

      $(this).addClass("is-active");
      $("[data-search-panel]")
        .eq(index)
        .addClass("is-active");
      _this.modalInstance.init();
    });

    // Load more results with defined type and append it in existing html
    $("body").on("click", "[data-search-more]", function() {
      const typeId = $(this).data("search-more");

      _this.loadMoreWithType(typeId).then(({ html, count }) => {
        // Find list and append results to html
        const list = $(`[data-search-list="${typeId}"]`);
        list.append(html);

        // Increment offset for lazy loading
        _this.counts[typeId] += count;

        // Hide / show load button
        count < SITE_SEARCH_LIMIT_DEFAULT ? $(this).hide() : $(this).show();
      });
    });
  }
}
