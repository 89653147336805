import $ from "jquery";
import MicroModal from "micromodal";

export default class Modal {
  constructor() {
    this.init();
  }

  clearModal = element => {
    const modalType = element.data("modal");
    switch (modalType) {
      case "video":
        element.find("[data-modal-video]").attr("src", "");
        break;

      case "audio":
        element.find("[data-modal-audio]").attr("src", "");
        break;

      case "image":
        element.find("[data-modal-image]").attr("src", "");
        break;

      default:
        console.warn("modalType not found !");
        break;
    }
  };

  init = () => {
    MicroModal.init({
      awaitCloseAnimation: true,
      disableScroll: true,
      onClose: el => this.clearModal($(el)),
    });
  };
}

/**
 * Modal trigger actions
 */

$("body").on("click", "[data-video]", function() {
  $("[data-modal-video]").attr("src", $(this).data("video"));
});

$("body").on("click", "[data-audio]", function() {
  $("[data-modal-audio]").attr("src", $(this).data("audio"));
});

$("body").on("click", "[data-image]", function() {
  $("[data-modal-image]").attr("src", $(this).data("image"));
});
