import $ from "jquery";
import Api from "../utils/api";

export default class NewsletterForm {
  constructor() {
    this.wrapper = $("[data-newsletter]");
    this.form = $("[data-newsletter-form]").find("form");
    this.email = $('[name="newsletter[email]"]');
    this.init();
  }

  submit = () => {
    this.form.addClass("is-loading");
    this.form.find(".newsletter__error").remove();

    const endpoint = this.form.attr("action");

    Api.post(endpoint, {
      form: this.form[0],
    })
      .then(() => {
        this.form.removeClass("is-loading");
        this.wrapper.addClass("is-valid");
      })
      .catch((error) => {
        this.form.removeClass("is-loading");
        this.email
          .parent()
          .append(`<div class="newsletter__error">${error.message}</div>`);
      });
  };

  init() {
    this.form.on("submit", (event) => {
      event.preventDefault();
      this.submit();
    });
  }
}
