import $ from "jquery";
import cookies from "js-cookie";

class Cookies {
  constructor() {
    this.cookiesWrapper = $("[data-cookies]");
    this.cookiesButton = $("[data-cookies-accept]");

    this.init();
  }

  init = () => {
    this.cookiesButton.on("click", event => {
      const target = $(event.currentTarget);
      const accept = target.data("cookies-accept");
      const value = accept ? "1" : "0";
      cookies.set("accept_cookies", value, { expires: 10 });
      this.cookiesWrapper.removeClass("is-visible");
    });
  };
}

export default Cookies;
