import $ from "jquery";
import MicroModal from "micromodal";
import Api from "../utils/api";
import getUrlParam from "../utils/getUrlParam";
import config from "../config";

class Find {
  constructor() {
    // DOM Elements
    this.wrapper = $("[data-find]");
    this.mapEl = $("[data-find-map]");
    this.searchInput = $("[data-find-input]");
    this.selectInput = $("[data-find-select]");
    this.grid = $("[data-find-grid]");
    this.moreButton = $("[data-find-more]");
    this.results = $("[data-find-results]");
    this.resultsTemplate = $("[data-find-results]").html();
    this.resetButton = $("[data-find-reset]");
    this.popup = $("[data-find-popup]");
    this.popupReset = $("[data-find-back]");

    // GMaps instances
    this.autocomplete = null;
    this.map = null;

    // Defaults
    this.defaultLat = 46.9480223;
    this.defaultLng = 2.5791517;
    this.defaultZoom = 5.8;
    this.defaultZoomCenter = 15;
    this.icons = {
      app: CONSTANTS.BASE_URL + "/img/markers/marker-pink.png",
      antenna: CONSTANTS.BASE_URL + "/img/markers/marker-blue.png",
    };

    // App state
    this.currentLat = CONSTANTS.LAT;
    this.currentLng = CONSTANTS.LNG;
    this.filter = parseInt($("[data-find-select='filter']").val(), 10);
    this.limit = parseInt(CONSTANTS.LIMIT, 10);
    this.displayedItems = parseInt(CONSTANTS.LIMIT, 10);
    this.currentPlace = null;
    this.markers = [];

    this.init();
  }

  /**
   * Data
   */

  fetchSpotsWithLocation = ({
    lat = this.currentLat,
    lng = this.currentLng,
    filter = this.filter,
    limit = this.limit,
    offset = this.displayedItems,
  } = {}) => {
    this.wrapper.addClass("is-loading");

    //console.log({ lat, lng, limit, offset, filter });

    return Api.post("/map-search.json", {
      formData: {
        lat,
        lng,
        filter,
        limit,
        offset,
      },
    })
      .then(response => {
        const { results } = response;
        this.wrapper.removeClass("is-loading");

        !results.length && this.resetMap();
        results.length < this.limit
          ? this.moreButton.hide()
          : this.moreButton.show();

        return response;
      })
      .catch(error => {
        this.wrapper.removeClass("is-loading");
        return error;
      });
  };

  handleSearchSubmit = (lat, lng) => {
    return this.fetchSpotsWithLocation({
      lat,
      lng,
      offset: 0,
    })
      .then(({ html, results, full_results }) => {
        this.displayedItems = results.length;
        this.currentLat = lat;
        this.currentLng = lng;

        const bounds = this.setupMarkers(full_results);
        this.scrollToMap();
        this.grid.html(html);
        this.currentPlace && this.showResultsDetails(full_results.length);

        return bounds;
      })
      .catch(error => console.log("error", error));
  };

  /**
   * Map
   */

  scrollToMap = () => {
    $("body, html").animate({ scrollTop: 0 });
  };

  setMapCenter = (coords, zoom = this.defaultZoomCenter) => {
    this.map.setCenter(coords);
    this.map.setZoom(zoom);
  };

  resetMap = () => {
    const coords = new google.maps.LatLng(this.defaultLat, this.defaultLng);
    this.setMapCenter(coords, this.defaultZoom);
    this.setupMarkers(CONSTANTS.FULL_RESULTS);
  };

  /**
   * Markers
   */

  setMarkersOnMap = map => {
    this.markers.forEach(marker => {
      marker.setMap(map);
      marker.addListener("click", () => this.handleMarkerClick(marker));
    });
  };

  clearMarkers = () => {
    this.setMarkersOnMap(null);
    this.markers = [];
  };

  hideMarkers = () => {
    this.setMarkersOnMap(null);
  };

  showMarkers = () => {
    this.setMarkersOnMap(this.map);
  };

  handleMarkerClick = ({ position, resultId, isAntenna }) => {
    this.timeout = setTimeout(() => {
      this.wrapper.addClass("is-loading");
    }, 500);
    this.setMapCenter(position);

    Api.post("/map-single-search.json", {
      formData: {
        id: resultId,
        is_antenna: isAntenna ? "1" : "0",
      },
    })
      .then(response => {
        clearTimeout(this.timeout);
        this.wrapper.removeClass("is-loading");

        this.popup.find(".find__popup__inner").html(response.html);
        this.popup.addClass("is-visible");
      })
      .catch(error => {
        console.warn(error);
        clearTimeout(this.timeout);
        this.wrapper.removeClass("is-loading");
      });
  };

  createMarker = (coords, resultId, isAntenna) => {
    return new google.maps.Marker({
      position: coords,
      resultId,
      isAntenna,
      icon: {
        url: isAntenna ? this.icons.antenna : this.icons.app,
      },
    });
  };

  setupMarkers = results => {
    this.clearMarkers();

    const bounds = new google.maps.LatLngBounds();

    results.forEach(({ latitude, longitude, is_antenna, id }) => {
      const coords = new google.maps.LatLng(latitude, longitude);
      bounds.extend(coords);

      const marker = this.createMarker(coords, id, is_antenna);
      this.markers.push(marker);
    });

    this.showMarkers();

    return bounds;
  };

  /**
   * Results
   */

  showResultsDetails = number => {
    const string = this.resultsTemplate
      .replace("%number%", number)
      .replace("%place%", this.currentPlace);
    this.results.html(string).show();
  };

  /**
   * Setup
   */

  init = () => {
    // Init autocomplete input
    this.autocomplete = new google.maps.places.Autocomplete(
      this.searchInput[0],
      config.autocomplete
    );

    this.map = new google.maps.Map(this.mapEl[0], {
      center: { lat: this.defaultLat, lng: this.defaultLng },
      zoom: this.defaultZoom,
      mapTypeControl: false,
      streetViewControl: false,
      fullscreenControl: false,
    });

    this.setupMarkers(CONSTANTS.FULL_RESULTS);

    /**
     * Listen to place autocomplete and send ajax request with coordinates and filters options
     */
    this.autocomplete.addListener("place_changed", () => {
      const place = this.autocomplete.getPlace();

      if (!place.geometry) return;
      const { lat, lng } = place.geometry.location;
      this.currentPlace = place.name;

      this.handleSearchSubmit(lat(), lng()).then(bounds =>
        this.map.fitBounds(bounds)
      );
    });

    /**
     * Load more results with current filters
     */

    this.moreButton.on("click", () => {
      this.fetchSpotsWithLocation()
        .then(({ results, html }) => {
          this.displayedItems += results.length;
          this.grid.append(html);
        })
        .catch(error => {
          console.log(error);
        });
    });

    /**
     * Filter data with select input
     */
    this.selectInput.on("change", event => {
      const { value } = event.target;
      this.filter = parseInt(value, 10);

      this.fetchSpotsWithLocation({ offset: 0 }).then(
        ({ results, full_results, html }) => {
          this.grid.html(html);
          if (this.currentPlace) this.showResultsDetails(full_results.length);

          if (0 === results.length) return this.clearMarkers();

          this.displayedItems = results.length;
          const bounds = this.setupMarkers(full_results);
          this.map.fitBounds(bounds);
        }
      );
    });

    /**
     * On localize click, move the map
     */

    $("body").on("click", "[data-find-coord]", event => {
      const { lat, lng } = $(event.currentTarget).data("find-coord");
      const coords = new google.maps.LatLng(lat, lng);
      const resultId = $(event.currentTarget).data("id");
      const isAntenna = $(event.currentTarget).data("antenna");

      // No marker on map => show it
      if (0 === this.markers.length) {
        const marker = this.createMarker(coords, resultId, isAntenna);
        this.markers.push(marker);
        this.showMarkers();
      }

      // Only one marker on map => clear it and create new one
      if (1 === this.markers.length) {
        this.clearMarkers();
        const marker = this.createMarker(coords, resultId, isAntenna);
        this.markers.push(marker);
        this.showMarkers();
      }

      // Markers already on map => just center the map in
      this.popup.removeClass("is-visible");
      this.setMapCenter(coords);
      this.scrollToMap();
    });

    this.resetButton.on("click", () => {
      this.searchInput.val("");
      this.results.hide();
      this.popup.removeClass("is-visible");
      // Reset select filter
      this.selectInput.prop("selectedIndex", 0);
      this.filter = 0;

      // No current place in state => no need to fetch all data, we can just reset map position
      if (!this.currentPlace) return this.resetMap();

      // Fecth all spots
      this.currentPlace = null;
      this.handleSearchSubmit(CONSTANTS.LAT, CONSTANTS.LNG).then(() =>
        this.resetMap()
      );
    });

    /**
     * Hide popup on map click
     */

    this.map.addListener("click", () => {
      this.popup.removeClass("is-visible");
    });

    /**
     * Back to results
     */

    this.popupReset.on("click", () => {
      this.popup.removeClass("is-visible");

      if (!this.currentPlace) return this.resetMap();

      // Center back map to all visible markers
      const bounds = new google.maps.LatLngBounds();

      this.markers.forEach(({ position }) => {
        const coords = new google.maps.LatLng(position.lat(), position.lng());
        bounds.extend(coords);
      });

      this.map.fitBounds(bounds);
    });

    /**
     * URL Param
     */

    const locationParam = getUrlParam("location");
    if (!locationParam) return;
    const [lat, lng] = locationParam.split(",");

    this.currentPlace = getUrlParam("place");
    this.handleSearchSubmit(lat, lng).then(bounds =>
      this.map.fitBounds(bounds)
    );
  };
}

export default Find;
