// import "promise-polyfill/src/polyfill";
import "../sass/index.sass";
import Swiper from "swiper";
import $ from "jquery";

import Api from "./utils/api.js";

import NewsletterForm from "./components/newsletter.js";
import LocationForm from "./components/location.js";
import Modal from "./components/modal.js";
import Find from "./components/find.js";
import News from "./components/news.js";
import Documents from "./components/documents.js";
import Search from "./components/search.js";
import Contact from "./components/contact.js";
import Cookies from "./components/cookies.js";
import ExtranetForm from "./components/extranetForm.js";
import Accordion from "./components/accordion.js";

new Modal();
new NewsletterForm();
new LocationForm();
new Cookies();

/**
 * Scroll header
 */

const header = $("[data-header]");
const headerThreshold = $("[data-header]").innerHeight();

$(window).on("scroll", () => {
  const scroll = $(window).scrollTop();
  scroll > headerThreshold
    ? header.addClass("is-compact")
    : header.removeClass("is-compact");
});

/**
 * Mobile menu
 */

$("[data-header-open]").on("click", () => {
  $("[data-header]").addClass("is-visible");
  $("body").addClass("no-scroll");
});

$("[data-header-close]").on("click", () => {
  $("[data-header]").removeClass("is-visible");
  $("body").removeClass("no-scroll");
});

/**
 * Drawer menu
 */

const toggleDrawer = () => {
  $("[data-drawer-trigger]").toggleClass("is-open");
  $("[data-drawer]").toggleClass("is-open");
  $("body").toggleClass("no-scroll");
};

$("[data-drawer-trigger]").on("click", toggleDrawer);
$("[data-drawer-overlay]").on("click", toggleDrawer);
$("[data-drawer-inner]").on("click", (event) => event.stopPropagation());

$("[data-dropdown-trigger]").on("click", (event) => {
  const target = event.currentTarget;
  $(target)
    .parent()
    .toggleClass("is-visible")
    .siblings()
    .removeClass("is-visible");
});

/**
 * Newsletter drawer
 */

$("[data-newsletter-trigger]").on("click", () => {
  $("[data-header]").removeClass("is-visible");
  toggleDrawer();
  const newsletterOffset = $("[data-newsletter-form]").offset().top;
  $("body, html").animate(
    {
      scrollTop: newsletterOffset,
    },
    1000
  );
});

/**
 * Partners
 */

const partnersSlider = new Swiper("[data-partners]", {
  direction: "horizontal",
  slidesPerView: 4,
  spaceBetween: 20,
  draggable: true,
  loop: true,
  breakpoints: {
    980: {
      slidesPerView: 2,
    },
    700: {
      slidesPerView: 1,
    },
  },
});

$("[data-partners-prev]").on("click", () => partnersSlider.slidePrev());
$("[data-partners-next]").on("click", () => partnersSlider.slideNext());

/**
 * News slider
 */

const newsSlider = new Swiper("[data-news]", {
  direction: "horizontal",
  slidesPerView: 3,
  spaceBetween: 20,
  breakpoints: {
    980: {
      slidesPerView: 2,
    },
    700: {
      slidesPerView: 1,
    },
  },
});

$("[data-news-prev]").on("click", () => newsSlider.slidePrev());
$("[data-news-next]").on("click", () => newsSlider.slideNext());

/**
 * Extranet's Home slider
 */

const extranetHomeSlider = new Swiper("[data-extranet-home]", {
  direction: "horizontal",
  slidesPerView: 1,
  pagination: {
    el: ".swiper-pagination",
  },
});

$("[data-extranet-home-prev]").on("click", () =>
  extranetHomeSlider.slidePrev()
);
$("[data-extranet-home-next]").on("click", () =>
  extranetHomeSlider.slideNext()
);

$("[data-extranet-login]").on("click", () => {
  Api.post("/stat-connection-infos.json", {
    formData: {
      id: $("[data-extranet-login]").data("extranet-login"),
    },
  })
    .then(({ login, password }) => {
      const form = $("[data-extranet-form]");
      form.find('[name="login"]').val(login);
      form.find('[name="password"]').val(password);
      form.submit();
    })
    .catch((error) => {
      console.warn(error);
      //window.location = "http://stats.app-reseau.eu/connect.php";
    });
});

/**
 * Page specifics modules
 */

const page = $("[data-page]").data("page");

switch (page) {
  case "find":
    new Find();
    break;

  case "news":
    new News();
    break;

  case "documents":
    new Documents();
    break;

  case "search":
    new Search();
    break;

  case "contact":
    new Contact();
    break;

  case "form_extranet":
    new ExtranetForm();
    break;

  case "extra_documents":
    new Accordion();
    break;

  case "extra_faq":
    new Accordion();
    break;

  default:
    break;
}
